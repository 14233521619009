import React, { useEffect } from "react";
import "./Altenator.scss";
import { useDispatch, useSelector } from "react-redux";
import { setAltFotos } from "../reducerBlogs";
import { blogFotosSelector } from "../../../../selectors/selectors";

const Altenator = () => {
    const fotosData = useSelector(blogFotosSelector);
    const dispathc = useDispatch();
    useEffect(() => {
        dispathc(setAltFotos());
        window.scrollTo(0, 0);
    }, [fotosData]);
    const link = (id) => {
        return fotosData.find((el) => el.id === id) === undefined
            ? ""
            : fotosData.find((el) => el.id === id).link;
        // перевірка, бо якщо в state було лише два обєкта а для завантаження сторінки треба 3 то буде помилка undefined обєкта якого нема, в такому випадку тимчасово даємо йому значення "" поки не завантажиться працюча ссилка
    };
    return (
        <div className="container">
            <div className="altenator">
                <h2 className="altenator__title title" tabIndex='0'>Будова генератора</h2>
                <a href={link(1)} target="_blank" rel="noopener noreferrer">
                    <img
                        src={link(1)}
                        alt="altenator structure будова генератора"
                    />
                </a>
                <h3 className="altenator__title title" tabIndex='0'>Генератор</h3>
                <p>
                    Генератор — це основне джерело живлення електрообладнання й
                    електросистем автомобіля. Працює при обертанні, яке
                    передається через приводний ремінь від колінчастого вала
                    двигуна.Для постійної роботи генератора необхідно завжди
                    стежити за натягом ременя привода, який визначається
                    прогином ременя в певному місці при додаванні до нього
                    встановленого зусилля.Під час роботи генератора акумуляторна
                    батарея заряджається до необхідного рівня.
                </p>
                <h3 className="altenator__title title" tabIndex='0'>Шків (обгінна муфта)</h3>
                <a href={link(3)} target="_blank" rel="noopener noreferrer"><img src={link(3)} alt="обгонна муфта шків" /></a>
                <p>
                    Обгінна муфта приводного ремня — це храповий механізм,
                    завдяки якому тяга передається при обертанні тільки в один
                    бік. Це сильно збільшує ресурс нагяжного механізму, роликів,
                    приводного ремня, підшипників генератора.
                </p>
                <h3 className="altenator__title title" tabIndex='0'>Перевірка генератора</h3>
                <p>
                    Деякі автомобілісти використовують небезпечний метод
                    перевірки роботи генератора способом відключення негативної
                    клеми від акумуляторної батареї. На автомобілі з
                    карбюраторним двигуном такий фокус робити можна, тільки
                    обережно. А от на машинах із сучасною системою керування
                    двигуном (з ЕБК) це може призвести до виходу з ладу
                    електронного блока керування та інших елементів
                    електросистеми автомобіля. Так робити не варто! Напруга
                    перевіряється тестером на акумуляторі. Якщо акумулятор
                    свинцевий, вона мусить бути в межах 13.9-14.9 вольт.
                    Перевіряють напругу на всіх можливиих умовах споживанння та
                    на всіх кількостях обертів колінчастого валу.
                </p>
                <a href={link(2)} target="_blank" rel="noopener noreferrer"><img src={link(2)} alt="altenator генератор" /></a>
                <h3 className="altenator__title title" tabIndex='0'>
                    Несправності генератора
                </h3>
                <ul>
                    <h3>Механічні:</h3>
                    <li>
                        зношення шківа генератора, заклинювання, ослаблення
                        (пропускання в обидві сторони) .
                    </li>
                    <li>корозія статора;</li>
                    <li>знос вугільних щіток;</li>
                    <li>знос колекторних кілець (колектора);</li>
                    <li>заклинювання/знос підшипників генератора;</li>
                    <li>механічне пошкодження корпусу генератора.</li>
                    <h3>Електричні:</h3>
                    <li>замикання та/або обрив обмотки статора;</li>
                    <li>замикання та/або обрив обмотки ротора;</li>
                    <li>вихід з ладу реле регулятора;</li>
                    <li>пробої або обрив діодів діодного моста.</li>
                </ul>
            </div>
        </div>
    );
};

export default Altenator;
