import React, { useEffect, useState } from "react";
import "./Text.scss";

const Text = ({ text }) => {
    const [color, setColor] = useState("red");
    useEffect(() => {
        const interval = setInterval(() => {
            setColor((prevColor) => (prevColor === "red" ? "black" : "red"));
        }, 600);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <p
            className={
                text ===
                "Виберіть діагностику відштовхуючись від того як проблема себе проявляє."
                    ? `text ${color}`
                    : "text"
            }
        >
            {text}
        </p>
    );
};

export default Text;
// В цьому коді ми використовуємо стан color, який починається зі значення "red".
// В useEffect ми створюємо setInterval, який змінює колір тексту з червоного на чорний та навпаки кожну секунду.
// Зверніть увагу на те, що ми викликаємо setColor з функцією, що отримує попереднє значення color,
// щоб оновити стан, не залежно від попереднього значення.
// Виклик clearInterval(interval) відміняє періодичну функцію, яку ми встановили з допомогою setInterval.
// Це дозволяє зупинити зміну кольору тексту кожну секунду, коли компонент більше не знаходиться на сторінці або
// коли компонент розмонтується.

// Коли ми викликаємо setInterval, воно повертає ідентифікатор таймера, який можна використовувати пізніше для його очищення.
// Цей ідентифікатор таймера передається функції clearInterval, яка зупиняє періодичне виконання функції з setInterval.

// У контексті нашого компонента, коли компонент буде розмонтовано (наприклад, якщо він вже не відображається на сторінці або
// змінюється шлях), ми хочемо зупинити таймер, щоб уникнути зайвих обчислень і додаткового навантаження на браузер.
// Тому ми викликаємо clearInterval(interval) в методі повернення з useEffect з використанням return. Це забезпечує очищення таймера,
// коли компонент розмонтується або коли змінюється залежність (у нашому випадку ми передаємо пустий масив [] як другий аргумент до
// useEffect, тому useEffect виконається лише один раз під час монтування та розмонтування).
